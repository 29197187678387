import React, {useState} from 'react';
import loudergif from "./images/Admin/louder.gif";
import AdminService from "./Services/AdminService";

const ButtonReset = () => {
    const [louder, setLouder] = useState(false);

    const reset = async () => {
        setLouder(true);
        await AdminService.setAllParams();
        setLouder(false);

    }

    return (
        louder?
            <button style={{
                width:"100px",
                height:"100px",

                background:"white",
                border:"1px solid red",
                color:"white",
                cursor:"default"
            }} disabled={true}><img src={loudergif} alt={""} /></button>
            :
            <button style={{
                width:"100px",
                height:"100px",
                background:"red",
                color:"white",
                cursor:"pointer"
            }} onClick={async ()=> await reset()}>{"Сброс всех параметров до изначальных"}</button>
    );
};

export default ButtonReset;