import React, { useState } from 'react';
import classes from './Rate.module.css';
import FormPage from '../../Components/Pages/FormPage/FormPage';
import { useForm } from 'antd/es/form/Form';
import { Modal } from 'antd';

const Rate = ({
  element,
  rateIndex,
  phoneActive,
  setPhoneActive,
  rates,
  isForm,
  rateActive,
  setRateActive,
  toFormPhone,
  setIsForm,
}) => {
  const [phone, setPhone] = useState(null);
  const [error, setError] = useState(false);
  const [form] = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneSave, setPhoneSave] = useState(null);

  const showModal = () => {
    toFormPhone(phoneSave);
    if (form) {
      form.setFieldValue('phone_take', phoneSave?.phone);
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const setPhoneClick = (phone, index) => {
    setPhoneActive(index);
    setRateActive(rateIndex);
    setPhone(phone);
  };

  const click = () => {
    if (phone === null) {
      setError(true);
      return;
    }

    if (
      phone?.phone === element?.phones[phoneActive].phone &&
      rateIndex === rateActive
    ) {
      toFormPhone(phone);
      setError(false);
    } else setError(true);
  };

  return (
    <>
      <div
        className={classes.rate}
        style={error ? { borderColor: 'red' } : { borderColor: 'black' }}
      >
        {element?.phones?.map((phone, index) => {
          let phoneSplit = phone.phone.toString().split('');

          return index < 6 ? (
            <div
              key={index}
              onClick={() => setPhoneClick(phone, index)}
              className={
                phoneActive === index && rateIndex === rateActive
                  ? classes.phoneActive
                  : classes.phone
              }
            >
              {'+7 ' +
                phoneSplit[0] +
                phoneSplit[1] +
                phoneSplit[2] +
                ' ' +
                phoneSplit[3] +
                phoneSplit[4] +
                phoneSplit[5] +
                ' ' +
                phoneSplit[6] +
                phoneSplit[7] +
                ' ' +
                phoneSplit[8] +
                phoneSplit[9]}
            </div>
          ) : (
            false
          );
        })}
        <input
          type="button"
          value="подключить"
          className={classes.plug}
          onClick={showModal}
        />

        <div className={classes.rateText}>
          {element.rate.tariffPrice + ' р./мес.'}
        </div>
      </div>
      <Modal
        title="Оставить заявку"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        className="bl-request-modal"
      >
        <FormPage
          phone={phone}
          rates={rates}
          isForm={isForm}
          setIsForm={setIsForm}
        />
        <div className="subtitle-modal-form">
          {
            'Нажимая на кнопку «Отправить», \nвы подтверждаете свое согласие \nна обработку'
          }{' '}
          <span className="bold">персональных данных.</span>
        </div>
      </Modal>
    </>
  );
};

export default Rate;
