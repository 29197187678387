import React, {useContext, useEffect, useRef, useState} from 'react';
import classes from './ChangeImageInput.module.css'
import imgSave from "../../../images/Admin/icon-save.png";
import imgTrue from "../../../images/Admin/galka .png";
import imgReset from "../../../images/Admin/reset.png";
import imgError from "../../../images/Admin/znak.png";
import gifLouder from '../../../images/Admin/louder.gif'
import {GlobalContext} from "../../../contexts/GlobalContext";
import AdminService from "../../../Services/AdminService";

const ChangeImageInput = ({title = "", keyC="", value = "", device="a" }) => {

    // Фунция рендеренга превью картинки
     async function handleFileSelect(evt) {
        let file = evt.target.files; // FileList object
        let f = file[0];
        let reader = new FileReader();
        let link = "";
        // Closure to capture the file information.
        reader.onload = (function(theFile) {
            return function(e) {
                // Render thumbnail.

                // link = ;
                setStateValueImg(e.target.result)
            };
        })(f);
        // Read in the image file as a data URL.
        await reader.readAsDataURL(f);
        return link
    }

    const {content, setContent} = useContext(GlobalContext)

    // Состаяние для хранения файла прикреплённого пользователем
    const [stateValue, setStateValue] = useState("");
    // Состояние для отображения превью загруженной картинки
    const [stateValueImg, setStateValueImg] = useState("");

    // Набор стилей под разные типы устройств
    const deviceList = {
        a:classes.input,
        d:classes.inputDesktop,
        m:classes.inputMobile
    }

    const [isCheckButton, setIsCheckButton] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLouder, setIsLouder] = useState(false);

    // Первоночальная подгруска установленного изображения
    useEffect(()=>{
        setStateValueImg(content[keyC]);
    }, [content[keyC]]);

    // Функция изменения файла внутри инпута
    const change = async (e) => {

        if(e.target.files[0]){
            let link = await handleFileSelect(e);
            setStateValue(e.target.files[0]);
            setIsCheckButton(true)
            setIsError(false)
        }
        else{
            setStateValue(null);
        }

    }

    const inputRef = useRef(null)

    // Функция сохранения, отправляет запрос к базе данных и перезаписывает значение в состаянии из useContext
    const save = async () => {
        setIsLouder(true)
        const response = await AdminService.upImgValue(keyC, stateValue)
        if(response.status){
            setContent({
                ...content,
                [keyC]:response.link
            });
            setIsError(false)
            setIsCheckButton(false)
            // inputRef.current.
        }
        else{
            setIsError(true)
            setIsCheckButton(false)
        }
        setIsLouder(false)

    }

    // Функция для востановления не перезаписанных данных
    const reset = () => {
        setStateValue(null)
        setStateValueImg(content[keyC])
        setIsCheckButton(false)
        setIsError(false)
    }



    return (
        <div className={classes.adminInput}>
            <div className={classes.title}>
                {title}
            </div>
            <div className={deviceList[device]}>
                <div className={classes.inputImg}>
                    <img src={stateValueImg} alt=""/>
                </div>
                <div className={classes.controls}>
                    {/*<input className={deviceList[device]} value={stateValue} onChange={(e)=>change(e)} type="text"/>*/}
                    <label htmlFor={"imageInput"+keyC} className={classes.inputLable}>Загрузите изображение</label>
                    <input type={"file"} id={"imageInput"+keyC} ref={inputRef} name="imageInput" accept="image/*"  onChange={(e)=>change(e)} />
                    <>
                        {
                            device==="m"?<br/>:null
                        }
                    </>

                    {
                        !isLouder?
                            !isError?
                                isCheckButton
                                    ?
                                    <>
                                        <button onClick={save} className={classes.saveButton}>
                                            <img src={imgSave} alt=""/>
                                        </button>
                                        <button onClick={reset} className={classes.saveResetButton}>
                                            <img src={imgReset} alt=""/>
                                        </button>
                                    </>
                                    :
                                    <button disabled={true} className={classes.saveTrueButton}>
                                        <img src={imgTrue} alt=""/>
                                    </button>
                                :
                                <>
                                    <button onClick={save} className={classes.saveErrorButton}>
                                        <img src={imgError} alt=""/>
                                    </button>
                                    <button onClick={reset} className={classes.saveResetButton}>
                                        <img src={imgReset} alt=""/>
                                    </button>
                                </>
                            :
                            <button disabled={true} className={classes.saveButton}>
                                <img src={gifLouder} alt=""/>
                            </button>
                    }

                </div>
            </div>


        </div>
    );
};

export default ChangeImageInput;