import React, { useEffect, useState } from 'react';
import classes from './ChangeReviewSliderItem.module.css';
import imgSave from '../../../../images/Admin/icon-save.png';
import imgReset from '../../../../images/Admin/reset.png';
import imgTrue from '../../../../images/Admin/galka .png';
import imgError from '../../../../images/Admin/znak.png';
import imgDel from '../../../../images/Admin/del.svg';
import gifLouder from '../../../../images/Admin/louder.gif';

const ChangeReviewSliderItem = ({
  save,
  // save = async (a, b, c) => {
  //     return {check: true}
  // },
  dell,
  // data = {
  //     id: 1,
  //     img: "",
  //     name: "",
  //     city: "",
  //     massage: ""
  // }
  data,
}) => {
  // const {content, setContent} = useContext(GlobalContext);
  // const [isEdit, setIsEdit] = useState(false);
  const [valueObject, setValueObject] = useState({
    id: null,
    img: '',
    name: '',
    city: '',
    massage: '',
  });
  const [imgPrev, setImgPrev] = useState(null);

  const [isFocus, setIsFocus] = useState(false);

  // Отвечают за вывод различных кнопок
  const [isCheckButton, setIsCheckButton] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLouder, setIsLouder] = useState(false);
  const [isDellError, setIsDellError] = useState(false);
  const [isDellLouder, setIsDellLouder] = useState(false);

  useEffect(() => {
    setValueObject(data);
    setImgPrev(data.img);
  }, [data]);

  // Фунция рендеренга превью картинки
  async function handleFileSelect(evt) {
    let file = evt.target.files; // FileList object
    let f = file[0];
    let reader = new FileReader();
    let link = '';
    reader.onload = (function (theFile) {
      return function (e) {
        setImgPrev(e.target.result);
      };
    })(f);
    // Read in the image file as a data URL.
    await reader.readAsDataURL(f);
    return link;
  }

  const changeImg = async (e) => {
    await handleFileSelect(e);
    setIsCheckButton(true);
    setValueObject({
      ...valueObject,
      img: e.target.files[0],
    });
  };

  const changeName = (e) => {
    setIsCheckButton(true);
    setValueObject({
      ...valueObject,
      name: e.target.value,
    });
  };

  const changeCity = (e) => {
    setIsCheckButton(true);
    setValueObject({
      ...valueObject,
      city: e.target.value,
    });
  };

  const changeMessage = (e) => {
    setIsCheckButton(true);
    setValueObject({
      ...valueObject,
      massage: e.target.value,
    });
  };

  const [isNameError, setIsNameError] = useState(false);
  const [isCityError, setIsCityError] = useState(false);
  const [isMessageError, setIsMessageError] = useState(false);

  const validFalse = () => {
    setIsNameError(false);
    setIsCityError(false);
    setIsMessageError(false);
  };
  const thisSave = async () => {
    if (valueObject.name === '') setIsNameError(true);
    else setIsNameError(false);

    if (valueObject.city === '') setIsCityError(true);
    else setIsCityError(false);

    if (valueObject.massage === '') setIsMessageError(true);
    else setIsMessageError(false);

    // console.log(isNameError+"||"+ isMessageError +"||"+ isCityError)

    if (
      valueObject.name === '' ||
      valueObject.city === '' ||
      valueObject.massage === ''
    )
      return;

    setIsLouder(true);
    const isSave = await save(valueObject);
    if (isSave?.check) {
      setIsError(false);
      setIsCheckButton(false);
    } else {
      setIsError(true);
    }
    setIsLouder(false);
  };

  const reset = () => {
    setValueObject(data);
    setImgPrev(data.img);
    setIsError(false);
    setIsCheckButton(false);
    validFalse();
  };

  const thisDel = async () => {
    setIsDellLouder(true);
    const isSave = await dell(valueObject);
    if (isSave?.check) {
      setIsDellError(false);
    } else {
      setIsDellError(true);
    }
    setIsDellLouder(false);
  };
  return (
    <div className={classes.ChangeReviewSliderItem}>
      <div className={classes.inputs}>
        <input
          type="file"
          id={'file_change' + data?.id}
          onChange={(e) => changeImg(e)}
        />
        <label htmlFor={'file_change' + data?.id} className={classes.fileLabel}>
          <img src={imgPrev} alt="" />
        </label>
        <input
          style={isNameError ? { border: 'red 2px solid' } : {}}
          type="text"
          onChange={(e) => changeName(e)}
          value={valueObject?.name}
        />
        <input
          style={isCityError ? { border: 'red 2px solid' } : {}}
          type="text"
          onChange={(e) => changeCity(e)}
          value={valueObject?.city}
        />
        <textarea
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          style={isMessageError ? { border: 'red 2px solid' } : {}}
          onChange={(e) => changeMessage(e)}
          value={valueObject?.massage}
        />
      </div>

      <div className={classes.buttons}>
        {isFocus ? (
          <></>
        ) : !isLouder ? (
          !isError ? (
            isCheckButton ? (
              <>
                <button onClick={thisSave} className={classes.saveButton}>
                  <img src={imgSave} alt="" />
                </button>
                <button onClick={reset} className={classes.saveResetButton}>
                  <img src={imgReset} alt="" />
                </button>
              </>
            ) : (
              <>
                <button disabled={true} className={classes.saveTrueButton}>
                  <img src={imgTrue} alt="" />
                </button>
                {!isDellError ? (
                  !isDellLouder ? (
                    <button
                      disabled={false}
                      onClick={thisDel}
                      className={classes.saveDelButton}
                    >
                      <img src={imgDel} alt="" />
                    </button>
                  ) : (
                    <button disabled={false} className={classes.saveDelButton}>
                      <img src={gifLouder} alt="" />
                    </button>
                  )
                ) : (
                  <button
                    disabled={false}
                    onClick={thisDel}
                    className={classes.saveDelButton}
                  >
                    <img src={imgError} alt="" />
                  </button>
                )}
              </>
            )
          ) : (
            <>
              <button onClick={thisSave} className={classes.saveErrorButton}>
                <img src={imgError} alt="" />
              </button>
              <button onClick={reset} className={classes.saveResetButton}>
                <img src={imgReset} alt="" />
              </button>
            </>
          )
        ) : (
          <button disabled={true} className={classes.saveButton}>
            <img src={gifLouder} alt="" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ChangeReviewSliderItem;
