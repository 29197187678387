import React, { useContext, useEffect, useState } from 'react';
import classes from './ThreePage.module.css';
import bt1 from './../../../images/FreePage/layer_6.png';
import bt2 from './../../../images/FreePage/layer_7.png';
import bt3 from './../../../images/FreePage/layer_8.png';
import bt4 from './../../../images/FreePage/layer_9.png';
import bt5 from './../../../images/FreePage/layer_10.png';
import bt6 from './../../../images/FreePage/layer_11.png';
import bt7 from './../../../images/FreePage/layer_12.png';
import bt8 from './../../../images/FreePage/layer_13.png';
import bt9 from './../../../images/FreePage/layer_14.png';
import bt0 from './../../../images/FreePage/layer_15.png';
import ruka from './../../../images/FreePage/Layer.png';
import fonInput from './../../../images/FreePage/fonInput.png';
import Rate from '../../Rate/Rate';
import PhoneService from '../../../Services/PhoneService';
import { GlobalContext } from '../../../contexts/GlobalContext';

const ThreePage = ({
  list,
  louder,
  setList,
  setLouder,
  RateList,
  toFormPhone,
  rates,
  isForm,
  setIsForm,
}) => {
  const [phoneActive, setPhoneActive] = useState();
  const [rateActive, setRateActive] = useState();

  const onEntre = async (e) => {
    if (e.keyCode === 13) {
      if (e.target.value.length < 1) return;

      await getPhoneNumber(e.target.value);
    }
  };

  const getPhoneNumber = async (number) => {
    if (!louder) {
      setLouder(true);
      setList(await PhoneService.getAllRatesPhones(RateList, true, number));
      setLouder(false);
    }
  };

  const { content } = useContext(GlobalContext);

  return (
    <div className={classes.Page}>
      <div className="headerClone" />

      <div className={classes.contentDesktop}>
        <img src={ruka} alt="" />
      </div>

      <div className={classes.content}>
        <div className={classes.title}>{content?.three_m_1}</div>

        <div className={classes.numbers}>
          <div className={classes.numberButtons}>
            <button
              className={classes.numberButton}
              onClick={() => getPhoneNumber(1)}
            >
              <img src={bt1} alt="" />
            </button>
            <button
              className={classes.numberButton}
              onClick={() => getPhoneNumber(2)}
            >
              <img src={bt2} alt="" />
            </button>
            <button
              className={classes.numberButton}
              onClick={() => getPhoneNumber(3)}
            >
              <img src={bt3} alt="" />
            </button>
            <button
              className={classes.numberButton}
              onClick={() => getPhoneNumber(4)}
            >
              <img src={bt4} alt="" />
            </button>
            <button
              className={classes.numberButton}
              onClick={() => getPhoneNumber(5)}
            >
              <img src={bt5} alt="" />
            </button>
            <button
              className={classes.numberButton}
              onClick={() => getPhoneNumber(6)}
            >
              <img src={bt6} alt="" />
            </button>
            <button
              className={classes.numberButton}
              onClick={() => getPhoneNumber(7)}
            >
              <img src={bt7} alt="" />
            </button>
            <button
              className={classes.numberButton}
              onClick={() => getPhoneNumber(8)}
            >
              <img src={bt8} alt="" />
            </button>
            <button
              className={classes.numberButton}
              onClick={() => getPhoneNumber(9)}
            >
              <img src={bt9} alt="" />
            </button>
            <button
              className={classes.numberButton}
              onClick={() => getPhoneNumber(0)}
            >
              <img src={bt0} alt="" />
            </button>
          </div>

          <div className={classes.numberTextBottom}>{content?.three_m_2}</div>
        </div>

        <div className={classes.titleDesktop}>{content?.three_d_1}</div>

        <div className={classes.setNumber}>
          <div className={classes.setNumberInput}>
            <img src={fonInput} alt="" />
            <input
              disabled={louder ? true : false}
              max={9}
              min={0}
              onInput={(e) => {
                if (e.target.value.length > 1)
                  e.target.value = e.target.value.substr(0, 1);
              }}
              onKeyDown={(event) => onEntre(event)}
              type="number"
            />
          </div>

          <div className={classes.setNumberText}>
            {content?.three_d_2}
            {/*{"Введите любимую цифру\nот 0 до 9 и нажмите Enter"}*/}
          </div>
        </div>

        <div className={classes.textDesktop}>{content?.three_d_3}</div>

        {!louder ? (
          <div className={classes.rates}>
            {list.map((element, rateIndex) => {
              return rateIndex < 5 ? (
                <Rate
                  key={rateIndex}
                  element={element}
                  rateIndex={rateIndex}
                  phoneActive={phoneActive}
                  setPhoneActive={setPhoneActive}
                  rateActive={rateActive}
                  setRateActive={setRateActive}
                  toFormPhone={toFormPhone}
                  rates={rates}
                  isForm={isForm}
                  setIsForm={setIsForm}
                />
              ) : (
                false
              );
            })}
          </div>
        ) : (
          <div className={classes.louder}>{content?.three_m_3}</div>
        )}

        {!louder ? (
          <div className={classes.ratesDesktop}>
            {list.map((element, rateIndex) => {
              return rateIndex < 3 ? (
                <Rate
                  key={rateIndex}
                  element={element}
                  rateIndex={rateIndex}
                  phoneActive={phoneActive}
                  setPhoneActive={setPhoneActive}
                  rateActive={rateActive}
                  setRateActive={setRateActive}
                  toFormPhone={toFormPhone}
                  rates={rates}
                  isForm={isForm}
                  setIsForm={setIsForm}
                />
              ) : (
                false
              );
            })}
          </div>
        ) : (
          <div className={classes.louderD}>{content?.three_d_4}</div>
        )}
      </div>
    </div>
  );
};

export default ThreePage;
