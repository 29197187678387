import React, {useContext, useRef, useState} from 'react';
import classes from './SevenPage.module.css'
import {GlobalContext} from "../../../contexts/GlobalContext";
import StopIntervalHelper from "../../Helpers/StopIntervalHelper";

const SevenPage = ({setIsFormPhoneMe}) => {

    const [isModal, setIsModal] = useState(false);
    const page = useRef()

    const OpenModal = () => {
        setIsModal(true)
        StopIntervalHelper();
        document.getElementsByName("SevenPage")[0].scrollIntoView()
    }

    const CloseModal = () => {
        setIsModal(false)
    }

    const {content} = useContext(GlobalContext);
    return (
        <div ref={page} className={classes.SevenPage}>

            <div className="headerClone"></div>

            <div className={classes.content}>

                <div className={classes.block}>
                    <div className={classes.title}>
                        {content?.seven_m_1}
                    </div>
                    <div className={classes.text}>
                        <span onClick={()=>setIsFormPhoneMe(true)}>{content?.seven_m_2}</span>{content?.seven_m_3}
                    </div>
                </div>
                <div className={classes.block}>
                    <div className={classes.title}>
                        {content?.seven_m_4}
                    </div>
                    <div className={classes.text}>
                        <span onClick={()=>setIsFormPhoneMe(true)}>{content?.seven_m_5}</span>
                        {content?.seven_m_6}
                    </div>
                </div>
                <div className={classes.block}>
                    <div className={classes.title}>
                        {content?.seven_m_7}
                    </div>
                    <div className={classes.text}>
                        {content?.seven_m_8}
                    </div>
                </div>
                <div className={classes.block + classes.blockModal}>

                    <div className={classes.link} onClick={() => OpenModal()}>
                        {
                            isModal ?
                                <>
                                    <div className={classes.Modal} onClick={(e) => {
                                        e.stopPropagation();
                                        CloseModal();
                                    }}>

                                    </div>
                                    <div className={classes.ModalContainer}>
                                        <div className={classes.ModalContent} onClick={(e) => e.stopPropagation()}>
                                            <div className={classes.ModalText}>
                                                {content?.seven_m_9}
                                            </div>
                                            <div className={classes.ModalText}>
                                                <span>{content?.seven_m_10}</span>{content?.seven_m_11}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <div></div>
                        }
                        {content?.seven_m_12}
                    </div>
                </div>


            </div>

            <div className={classes.contentD}>

                <div className={classes.block}>
                    <div className={classes.title}>
                        {content?.seven_d_1}
                    </div>
                    <div className={classes.text}>
                        <span>
                            <div style={{cursor:'pointer'}} onClick={()=>setIsFormPhoneMe(true)}>{content?.seven_d_2}</div>
                            <div className={classes.qrCode}>
                                <img src={content?.seven_img_1} alt=""/>
                            </div>
                        </span>
                        {content?.seven_d_3}
                    </div>
                </div>
                <div className={classes.block}>
                    <div className={classes.title}>
                        {content?.seven_d_4}
                    </div>
                    <div className={classes.text}>
                        <span onClick={()=>setIsFormPhoneMe(true)}>{content?.seven_d_5}</span>

                        {content?.seven_d_6}

                    </div>
                </div>
                <div className={classes.block}>
                    <div className={classes.title}>
                        {content?.seven_d_7}
                    </div>
                    <div className={classes.text}>
                        {content?.seven_d_8}
                    </div>
                </div>
                <div className={classes.block + classes.blockModal}>


                    <div className={classes.link}
                        // onClick={() => setIsModal(!isModal)}
                         onClick={() => OpenModal()}

                    >
                        {
                            isModal ?
                                <>
                                    <div className={classes.Modal} onClick={(e) => {
                                        e.stopPropagation();
                                        CloseModal();
                                    }}>

                                    </div>
                                    <div className={classes.ModalContainer}>
                                        <div className={classes.ModalContent} onClick={(e) => e.stopPropagation()}>
                                            <div className={classes.ModalText}>
                                                {content?.seven_d_9}
                                            </div>
                                            <div className={classes.ModalText}>
                                                <span>{content?.seven_d_10}</span>{content?.seven_d_11}
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <div></div>
                        }
                        {content?.seven_d_12}
                    </div>
                </div>


            </div>

            <div className={classes.fonTop}/>
            <div className={classes.fonBottom}/>
        </div>
    );
};

export default SevenPage;