import axios from 'axios';

class PhoneService {
  static async getPhones() {
    let response;

    await axios
      .get(
        'https://www.anncom.ru/dialer/lendingbezlimit/main.py?pattern=standard&user_id=' +
          localStorage.getItem('user_id') +
          '&tariff=1000'
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {});

    return response;
  }

  static async getPhonesRate(price) {
    let response;

    await axios
      .get(
        'https://www.anncom.ru/dialer/lendingbezlimit/main.py?pattern=phones&user_id=' +
          localStorage.getItem('user_id') +
          '&tariff=' +
          price
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {});

    return response?.data?.response?.items;
  }

  static async getPhonesRateNumber(price, number) {
    let response;
    await axios
      .get(
        'https://www.anncom.ru/dialer/lendingbezlimit/main.py?pattern=phones&user_id=' +
          localStorage.getItem('user_id') +
          '&tariff=' +
          price +
          '&phone_pattern=NNNNNNN' +
          number.toString() +
          number.toString() +
          number.toString()
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {});

    return response?.data?.response?.items;
  }

  static async getAllRatesPhones(rates, isNumber = false, number = 0) {
    let list = [];
    let phones;
    let rateObg = {
      rate: {},
      phones: [],
    };

    for (const rate of rates) {
      rateObg = {};

      rateObg.rate = {
        tariffPrice: rate.tariffPrice,
        min: rate.min,
        gb: rate.gb,
        sms: rate.sms,
      };

      let phones;

      if (isNumber)
        phones = await this.getPhonesRateNumber(rate.tariffPrice, number);
      else phones = await this.getPhonesRate(rate.tariffPrice);

      rateObg.phones = phones;

      list.push(rateObg);
    }

    return list;
  }

  static async getAllRatesPhonesSlider(rates) {
    let list = [];
    let phones;
    let rateObg = {
      rate: {
        text: '',
      },
      phones: [],
    };

    for (const rate of rates) {
      rateObg.rate.text = rate.tariffPrice + ' р./мес.';

      const phones = await this.getPhonesRate(rate.tariffPrice);

      rateObg.phones = [];

      for (const phone of phones) {
        rateObg.phones.push(phone.phone);

        if (rateObg.phones.length === 6) break;
      }

      list.push(rateObg);
    }

    return list;
  }

  static async getPhonesPhone(userId) {
    let response;

    await axios
      .get(
        'https://www.anncom.ru/dialer/lendingbezlimit/main.py?pattern=static_phone&user_id=' +
          localStorage.getItem('user_id')
      )
      .then((res) => {
        response = res;
      })
      .catch((error) => {});

    return response?.data?.response?.items;
  }

  static async postForm(phone, name, userPhone) {
    let response;
    let str = '';
    str += '?user_id=' + localStorage.getItem('user_id');

    if (phone) {
      str += '&phone_take=' + phone.phone;
      str += '&phone_tariff=' + phone.tariff_price;
      str += '&phone_id=' + phone.id;
    }
    str += '&cli_name=' + name;
    str += '&cli_phone=' + userPhone;

    await axios
      .get(
        'https://www.anncom.ru/dialer/lendingbezlimit/registration_number_lending.py' +
          str
      )
      .then((res) => {
        response = true;
      })
      .catch((error) => {
        response = false;
      });

    return response;
  }

  static async postFormCol(phone) {
    let response;
    let str = '';
    str += '?id=' + localStorage.getItem('user_id');
    str += '&user_phone=' + phone;

    await axios
      .get(
        'https://www.anncom.ru/dialer/lendingbezlimit/registration_number_call_my.py' +
          str
      )
      .then((res) => {
        response = true;
      })
      .catch((error) => {
        response = false;
      });

    return response;
  }

  static async postFormFeedback(name, region, message) {
    let response;
    let str = '';
    str += '?user_id=' + localStorage.getItem('user_id');
    str += '&name=' + name;
    str += '&region=' + region;
    str += '&message=' + message;

    await axios
      .get(
        'https://www.anncom.ru/dialer/lendingbezlimit/registration_number_feedback.py' +
          str
      )
      .then((res) => {
        response = true;
      })
      .catch((error) => {
        response = false;
      });

    return response;
  }
}

export default PhoneService;
