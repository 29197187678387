import axios from 'axios';
import two_img1 from '../images/TwoPage/1_mln.png';
import two_img2 from '../images/TwoPage/3.png';
import two_img3 from '../images/TwoPage/layer_6.png';
import two_img4 from '../images/TwoPage/layer_7.png';
import video from '../video/NoMoney.mp4';
import qrCode from '../images/SevenPage/qrcodv3.png';
import eight_imgContent1 from '../images/EightPage/layer_4.png';
import eight_imgContent2 from '../images/EightPage/layer_5.png';
import eight_imgContent3 from '../images/EightPage/layer_6.png';
import eight_imgDesktopContent1 from '../images/EightPage/7.png';
import eight_imgDesktopContent2 from '../images/EightPage/8.png';
import eight_imgDesktopContent3 from '../images/EightPage/9.png';
import eight_logo from '../images/EightPage/logo.png';
import eight_logoD from '../images/EightPage/logoD.png';
import nine_logo from '../images/NinePage/logo.png';
import nine_img4 from '../images/NinePage/foto.png';
import nine_goldLogo from '../images/NinePage/goldLogo.png';
import nine_img1 from '../images/NinePage/img1.png';
import nine_img2 from '../images/NinePage/img2.png';
import nine_img3 from '../images/NinePage/img3.png';
import ten_avatar1 from '../images/TenPage/avatar1.png';
import ten_avatar2 from '../images/TenPage/avatar2.png';
import ten_avatar3 from '../images/TenPage/avatar3.png';
import DefaultAvatarHelper from '../Components/Helpers/DefaultAvatarHelper';

class AdminService {
  // static url_api = "http://158.160.101.200:5700/";
  static url_api = 'https://lab.anncom.ru/';

  static async sleep(ms) {
    return await new Promise((resolve) => setTimeout(resolve, ms));
  }

  static async getAll() {
    // Временные масивы с изображениями и видео :: Удалить после реализации
    let files = {
      ten_comments: [
        {
          id: 1,
          img: 'https://i.ibb.co/9rCrCZy/foto.png',
          name: 'Олег',
          city: 'Москва',
          massage:
            'Получил бесплатно шикарный номер вместе с контрактом за 2500 р. ' +
            'Бесплатно доставили сим-карту, сразу же подключился. ' +
            'Через какое то время подключил двух друзей через ссылку и теперь плачу только 60% от тарифа.',
        },
        {
          id: 2,
          img: ten_avatar2,
          name: 'Сергей',
          city: 'Моск. обл.',
          massage:
            'Даже не предполагал, что красивый номер в наше время можно получить бесплатно. А безлимитный мобильный интернет - вообще что-то из области фантастики. ' +
            'Связь отличная, как и сервис. Спасибо.',
        },
        {
          id: 3,
          img: ten_avatar3,
          name: 'Анна',
          city: 'Владикавказ',
          massage:
            'Взяла красивый и легкий номер специально для работы. И не разу не пожалела. Тарифы адекватные. Качество связи тоже.',
        },
      ],
    };

    let response = {
      status: false,
      content: {},
    };

    await axios
      .get(this.url_api + 'all_data')
      .then((res) => {
        response.status = true;

        Object.assign(response?.content, res?.data?.Response);

        response.content = {
          ...response?.content,
          ten_comments: response?.content?.comment,
        };
      })
      .catch(() => {
        response.status = false;
      });

    return response;
  }

  static async upTextValue(key, value) {
    this.proverka();

    let formData = new FormData();
    formData.set('key', key);
    formData.set('val', value);

    let response = false;

    await axios
      .post(
        this.url_api + 'text_field',
        {
          key: key,
          val: value,
        },
        // formData
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: '*/*',
            Authorization: 'Bearer ' + localStorage.getItem('user_key'),
          },
          method: 'post',
        }
      )
      .then(() => {
        response = true;
      })
      .catch((error) => {
        this.Error401(error);
        response = false;
      });

    return response;
  }

  static dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  static async setAllParams() {
    const params = {
      one_m_1: 'КРАСИВЫЙ НОМЕР',
      one_m_2: 'БЕСПЛАТНО',
      one_m_3: 'ИЗ САМОЙ БОЛЬШОЙ\nБАЗЫ В РОССИИ',
      one_d_4: 'КРАСИВЫЙ НОМЕР',
      one_d_5: 'БЕСПЛАТНО',
      one_d_6: 'Из самой большой\nбазы в России',
      one_d_7: '- Всегда в наличии более 1 000 000 красивых\nномеров',
      one_d_8:
        '- Поможем подобрать любимые цифры под дату рождения, номер машины,\nкод региона',
      one_d_9: '- Можно ',
      one_d_10: 'получать комиссию',
      one_d_11: '- Возможность вообще не ',
      one_d_12: 'платить за связь',
      phone_1: 'Загружаем\n номера...',
      phone_2: 'ЗАКАЗАТЬ',
      //********************************************************************************************
      two_m_1: 'Всегда в наличии более\nмиллиона красивых\nномеров',
      two_m_2:
        'Поможем подобрать любимые\nцифры под дату рождения,\nномер машины',
      two_m_3: 'Можно получать\nкомиссию',
      two_m_4: 'Возможность\nвообще не платить\nза связь',
      three_m_1: 'ВЫБЕРИ \n ЛЮБИМУЮ ЦИФРУ!',
      three_m_2: 'Нажмите любую цифру от О до 9',
      three_m_3: 'Загрузка тарифов...',
      three_d_1: 'Номер по любимым цифрам - ЛЕГКО!',
      three_d_2: 'Введите любимую цифру\nот 0 до 9',
      three_d_3: 'Возможные номера:',
      three_d_4: 'Загрузка тарифов...',
      //********************************************************************************************
      four_1: 'ПОЧЕМУ ИМЕННО МЫ',
      four_2: 'Красивый номер бесплатно к тарифу',
      four_3: 'Возможность снижать тариф до нуля',
      four_4: 'Кешбэк за подключение Вашими знакомыми',
      four_5: 'Подключение по всей России',
      four_6: 'Бесплатная доставка sim-карты',
      four_7: 'Безлимитные тарифы на связь и интернет',
      //********************************************************************************************
      five_1: 'ТАРИФЫ',
      five_2: '\nС БЕСПЛАТНЫМ КРАСИВЫМ НОМЕРОМ',
      five_3: 'Во всех пакетах:',
      five_4: 'Безлимит на Билайн',
      five_5: 'Безлимит на Соцсети',
      five_6: 'Безлимит на Мессенджеры',
      five_7: 'Безлимит на Видео',
      five_8: 'Безлимит на Музыку',
      five_9: 'Безлимит на Карты',
      five_10: 'Безлимит на Почту',
      five_11: 'Безлимит на Безлимит',
      //********************************************************************************************
      six_1: 'ХОТИТЕ ВООБЩЕ \n НЕ ПЛАТИТЬ ЗА СВЯЗЬ?',
      six_2: 'У нас \n это возможно!',
      six_3:
        'Просто приводите близких,\n' +
        ' друзей и знакомых в Безлимит\n' +
        'и получайте скидку на \n' +
        'абонентскую плату до 100%!',
      six_4:
        'Просто приводите близких,' +
        ' друзей и знакомых\n в Безлимит' +
        'и получайте скидку на ' +
        'абонентскую плату до 100%!',
      seven_m_1: 'где взять ссылку?',
      seven_m_2: 'Приложение Безлимит',
      seven_m_3: ' -> Главная -> Баннер «Не плати за связь» -> Поделиться',
      seven_m_4: 'как участвовать в акции?',
      seven_m_5: 'В Приложение Безлимит',
      seven_m_6:
        '\nВы найдете ссылку-приглашение. Скопируйте и отправьте ее своему знакомому. Он переходит по ссылке, подключает номер, а Вы получаете скидку на связь.',
      seven_m_7: 'Что вы получите?',
      seven_m_8:
        'За месяц вы получите 20% от абонентской платы друга. Начисление коинов производится ежедневно в равных долях. Накопленными коинами можно оплачивать свою связь. Приведите несколько друзей и получайте коины с каждого. Как только подключиться Ваш пятый знакомый, связь для Вас станет бесплатной!',
      seven_m_9:
        'Акция действует, если номер друга был подключен по ссылке-приглашению. Коины начисляются каждый день, пока подключенный по ссылке-приглашению номер активен (не заблокирован). Количество человек, приведенных по акции, не ограничено. Максимальная скидка на связь — 100%.',
      seven_m_10: 'Безлимиткоин',
      seven_m_11:
        ' (коин) — расчетная единица, зачисляемая на бонусный счет пользователя за подключенный по ссылке-приглашению и активный номер. Сумма начисленных бонусов может быть использована пользователем для оплаты мобильной связи и услуг и не подлежит обмену на денежные средства.',
      seven_m_12: 'Полные условия акции',
      seven_d_1: 'где взять ссылку?',
      seven_d_2: 'Приложение Безлимит',
      seven_d_3: '-> Главная -> баннер ->\n«Не плати за связь» -> Поделиться',
      seven_d_4: 'как участвовать в акции?',
      seven_d_5: 'В Приложение Безлимит',
      seven_d_6:
        ' Вы найдете ссылку-приглашение.\n' +
        ' Скопируйте и отправьте ее своему' +
        ' знакомому. Он переходит по ссылке,\n' +
        ' подключает номер, а Вы получаете' +
        ' скидку на связь.',
      seven_d_7: 'Что вы получите?',
      seven_d_8:
        'За месяц вы получите 20%' +
        ' от абонентской платы друга.\n' +
        ' Начисление коинов производится' +
        ' ежедневно в равных долях.\n' +
        ' Накопленными коинами можно' +
        ' оплачивать свою связь.\n' +
        ' Приведите несколько друзей' +
        ' и получайте коины с каждого.\n' +
        ' Как только подключиться Ваш' +
        ' пятый знакомый, связь для Вас\n' +
        ' станет бесплатной!',
      seven_d_9:
        'Акция действует, если номер друга был подключен по\nссылке-приглашению.' +
        ' Коины' +
        ' начисляются каждый день, пока подключенный по ссылке-приглашению номер' +
        ' активен (не заблокирован). Количество человек, приведенных по акции, не' +
        ' ограничено. Максимальная скидка на связь — 100%.',
      seven_d_10: 'Безлимиткоин',
      seven_d_11:
        ' (коин) — расчетная единица, зачисляемая на бонусный счет пользователя за подключенный по ссылке-приглашению и активный номер. Сумма начисленных бонусов может быть использована пользователем для оплаты мобильной связи и услуг и не подлежит обмену на денежные средства.',
      seven_d_12: 'Полные условия акции',
      eight_m_1: 'КАК МЫ РАБОТАЕМ',
      eight_m_2: 'знакомство\nи выбор номера',
      eight_m_3:
        'на сайте представлены тарифные планы и варианты номеров, а также тезисно описаны возможности и скидки',
      eight_m_4: 'приобретение\nи активация',
      eight_m_5:
        'вы получаете sim-карту и подключаете её дистанционно. Отмечаем что оплату можно произвести после активации',
      eight_m_6: 'удовольствие\nот пользования',
      eight_m_7: 'теперь у Вас есть красивый номер и выгодный тарифный план',

      eight_d_1: 'Как мы работаем',
      eight_d_2: 'знакомство\nи выбор номера',
      eight_d_3: 'приобретение\nи активация',
      eight_d_4: 'удовольствие\nот полльзования',
      eight_d_5:
        'На сайте представлены \n' +
        'тарифные планы и варианты \n' +
        'номеров, а так же тезисно\n' +
        'описаны возможности \n' +
        'и скидки',
      eight_d_6:
        'Вы получаете сим карту\n' +
        ' или подключаете её\n' +
        'дистанционно. Отмечаем,\n' +
        'что оплату можно\n' +
        'произвести после \n' +
        'получения и активации',
      eight_d_7: 'Теперь у Вас есть\nкрасивый номер и\nвыгодный тарифный\nплан',
      nine_1: 'О КОМПАНИИ',
      nine_2:
        'Мы развиваем набор услуг сотовой связи с 2012 года. За это время наши специалисты тщательно' +
        'изучили возможности ведущих сотовых операторов и поставщиков других услуг, которые мы включаем в' +
        'свои тарифы безлимитной мобильной связи. Теперь мы можем предложить нашим клиентам лучшее.',
      nine_3:
        'Тарифные планы «Безлимит» в настоящее время представляют собой сочетание трёх важных преимуществ,' +
        'привлекательных как для тех клиентов, которые хотят получить в своё распоряжение безлимитную сотовую' +
        'связь по разумной цене, так и требовательных клиентов, предпочитающих максимальный комфорт',
      ten_1: 'ОТЗЫВЫ \n НАШИХ КЛИЕНТОВ',
      ten_2: 'Вы можете оставить \n свой отзыв заполнив форму',
      ten_3: 'Закрыть форму',
      ten_form_1: 'Ваши имя',
      ten_form_2: 'Город/регион',
      ten_form_3: 'Ваше мнение очень важно для нас',

      //********************************************************************************************
      eleven_title_1: 'ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ',
      eleven_1: 'Можно ли уменьшить тариф? Можно',
      eleven_2:
        '   Мы привлекаем всё больше новых пользователей и готовы снижать Ваш тариф вплоть до 100%:\n\n' +
        '   Пользуйтесь нашими номерами и окружающие сами спросят "где взять такой же"\n\n' +
        '   Воспользуйтесь супер-ссулкой в вашем ЛК, где ваши знакомые сами смогут посмотреть номера и условия, а Вы получите скидку за каждого.\n\n' +
        '   Пример у Вас тариф 1000р/мес и вы разослали ссылку и включилось 3 номера с тарифом 1000р/мес, вы получаете скидку на ваш тариф на постоянной основе в размере 600р.\n\n' +
        '   Теперь Ваш тариф не 1000р/мес, а 400р/мес.\n\n' +
        '   Или вы берете номер себе, супруге и ребенку. Соответственно ваш Ваш тариф будет уменьшен на 400р и составит уже не 1000р/мес, а 600.\n\n' +
        '   Напоминаем, что Вам ничего рекламировать не надо, ссылка из Вашего ЛК само предоставит всю необходимую информацию\n\n' +
        '   Уточните у менеджера все возможные варианты сотрудничества с физ.лицами',
      eleven_3: 'Хочу красивый номер, но не терять свой. Легко!',
      eleven_4:
        '   Мы понимаем, что вы пользовались своим номером достаточно долго и все его знают и Вы к нему привыкли. Поэтому мы даже рассматриваем вариант, что вы его оставите. Если несколько вариантов.\n' +
        '\n' +
        '   1-й мы переводим Ваш номер на тариф с абонентской платой 0 рублей и вы сможете продолжать получать на него смс и входящие звонки. Тем самым вы будете пользоваться красивым номером, но не потеряете контакты и не придется перерегистрироваться\n' +
        '\n' +
        '   2-й это функция "легкий шаг", она заключается в том, что вы выключаете свой номер, но при звонке на него будут происходить следующие действия -робот будет диктовать абонент Ваш новый номер -вам придет смс, что абонент пытался Вам дозвониться и уведомлен о переходе на новый номер\n' +
        '\n' +
        '   В ОБОИХ СЛУЧАЯХ ВЫ НЕ ПОТЕРЯЕТЕ НИ ОДНОГО АБОНЕНТА И БУДИТЕ ИМЕТЬ ДОСТУП К ВАШЕМУ НОМЕРУ.',
      eleven_5: 'Как оформить номер на себя? Мгновенно!',
      eleven_6:
        'Зарегистрировать номер можно несколькими способами:\n' +
        '\n' +
        '   -с помощью курьера при получении\n' +
        '\n' +
        '   -в личном кабинете\n' +
        '\n' +
        '   -звонок на горячую линию',
      eleven_7: 'Как получить номер?',
      eleven_8:
        'У нас есть несколько вариантов доставки:\n' +
        '\n' +
        '   -курьером (если такая услуга предусмотрена в вашем городе)\n' +
        '\n' +
        '   -через CDEK\n' +
        '\n' +
        '   -почтой России\n' +
        '\n' +
        'ВНИМАНИЕ ДОСТАВКА БЕСПЛАТНАЯ',
      eleven_9: 'Какие юридические документы я могу получить?',
      eleven_10:
        '   На нашем сайте есть вся необходимая документация, так же вы можете получить договор в бумажном виде',
      eleven_11: 'Могу ли я заработать на подключении красивых номеров?',
      eleven_12:
        '   Конечно, сотрудничество с физ.лицами наша основная деятельность\n' +
        '\n' +
        '   Как это работает, Вам в целом предлагать номера вашим друзьям, близким, знакомым и коллегам не надо. Вам необходимо иметь красивый номер и разослать всем реферальную ссылку, которая позволит им выбрать себе номер и проконсультироваться с оператором по интересующим вопросам. Вы в свою очередь получите скидку на ваш тариф и комиссию с первого пополнения в размере 50% от стоимости подключаемого тарифа. ПРИМЕР:\n' +
        '\n' +
        '   Вы разослали ссылку 20 коллегам или знакомым и 3 из них подключили красивые номера с тарифом 1000р/мес. Вы получаете скидку на Ваш тариф в размере 800р на постоянной основе и 1500р комиссию которую можно вывести на карту\n' +
        '\n' +
        '   более подробно можно проконсультироваться с менеджером',

      //********************************************************************************************
      twelve_1: 'КОНТАКТЫ',
      twelve_2:
        'Главный офис:\n123290, г. Москва, 1-ый Магистральный тупик, дом 5А, офис 301',
      twelve_3:
        'Филиал Ульяновск:\n1432035, г. Ульяновск, проспект Гая, дом 100, 1-й подъезд, 3-й этаж',
      twelve_4:
        'Филиал Казань:\n' +
        '420012, г. Казань,' +
        'улица Щапова, дом 26, ' +
        '2-й этаж',

      twelve_m_1: 'E-mail: info@bezlimit.ru',
      twelve_m_2: 'Компания ООО “Безлимит”',
      twelve_m_3: 'ОГРН 1197746244750',

      twelve_d_1: 'Компания ООО “Безлимит”',
      twelve_d_2: 'www.bezlimit.ru',
      twelve_d_3: 'ОГРН 1197746244750',
      twelve_d_4: 'E-mail: info@bezlimit.ru',

      twelve_link_vk_1: 'https://vk.com/bezlimit.official',
      twelve_link_youtube_1:
        'https://www.youtube.com/channel/UCs2uaoSV9noMOKhXeLW56kA/featured',
      twelve_link_bezlimit_1: 'http://www.bezlimit.ru/',

      //********************************************************************************************
      form_1: 'Во всех пакетах:',
      form_2: 'Безлимит на Билайн',
      form_3: 'Безлимит на Соцсети',
      form_4: 'Безлимит на Мессенджеры',
      form_5: 'Безлимит на Видео',
      form_6: 'Безлимит на Музыку',
      form_7: 'Безлимит на Карты',
      form_8: 'Безлимит на Почту',
      form_9: 'Безлимит на Безлимит',
      form_form_1: 'Ваше имя',
      form_form_2: 'Ваш номер телефона',
      form_form_3: 'Отправить заявку',
    };

    const paramsFiles = {
      two_m_img_1: two_img1,
      two_m_img_2: two_img2,
      two_m_img_3: two_img3,
      two_m_img_4: two_img4,
      six_video_1: video,
      seven_img_1: qrCode,
      eight_img_m_1: eight_imgContent1,
      eight_img_m_2: eight_imgContent2,
      eight_img_m_3: eight_imgContent3,
      eight_img_d_1: eight_imgDesktopContent1,
      eight_img_d_2: eight_imgDesktopContent2,
      eight_img_d_3: eight_imgDesktopContent3,

      eight_logo_m_1: eight_logo,
      eight_logo_d_1: eight_logoD,
      nine_img_m_1: nine_logo,
      nine_img_m_2: nine_img4,
      nine_img_d_1: nine_goldLogo,
      nine_img_d_2: nine_img1,
      nine_img_d_3: nine_img2,
      nine_img_d_4: nine_img3,
    };

    const paramsReview = [
      {
        id: 1,
        img: ten_avatar1,
        name: 'Олег',
        city: 'Москва',
        massage:
          'Получил бесплатно шикарный номер вместе с контрактом за 2500 р. ' +
          'Бесплатно доставили сим-карту, сразу же подключился. ' +
          'Через какое то время подключил двух друзей через ссылку и теперь плачу только 60% от тарифа.',
      },
      {
        id: 2,
        img: ten_avatar2,
        name: 'Сергей',
        city: 'Моск. обл.',
        massage:
          'Даже не предполагал, что красивый номер в наше время можно получить бесплатно. А безлимитный мобильный интернет - вообще что-то из области фантастики. ' +
          'Связь отличная, как и сервис. Спасибо.',
      },
      {
        id: 3,
        img: ten_avatar3,
        name: 'Анна',
        city: 'Владикавказ',
        massage:
          'Взяла красивый и легкий номер специально для работы. И не разу не пожалела. Тарифы адекватные. Качество связи тоже.',
      },
    ];

    for (let key in params) {
      console.log(key + '||' + params[key]);
      await this.upTextValue(key, params[key]);
    }

    const toDataURL = async (url) =>
      await fetch(url)
        .then((response) => response.blob())
        .then(
          async (blob) =>
            await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );

    for (let keyFile in paramsFiles) {
      console.log(keyFile + '|| .' + paramsFiles[keyFile]);
      let str = paramsFiles[keyFile];
      let file = null;
      try {
        if (str.substring(0, 4) === 'data') {
          file = this.dataURLtoFile(paramsFiles[keyFile], keyFile);
        } else {
          await toDataURL(paramsFiles[keyFile]).then((dataUrl) => {
            file = this.dataURLtoFile(dataUrl, keyFile);
          });
        }
        await this.upImgValue(keyFile, file);
      } catch (error) {}
    }

    let reviewResponse = await this.GetAllReview();

    if (reviewResponse.check) {
      reviewResponse.ten_comments.map(async (element) => {
        await this.DellReview(element.id);
      });
    }

    for (let key in paramsReview) {
      console.log(key + '||' + paramsReview[key]);
      let file = null;
      let str = paramsReview[key].img;
      try {
        if (str.substring(0, 4) === 'data') {
          file = this.dataURLtoFile(paramsReview[key].img, key);
        } else {
          await toDataURL(paramsReview[key].img).then((dataUrl) => {
            file = this.dataURLtoFile(dataUrl, key);
          });
        }
      } catch {
        file = DefaultAvatarHelper();
      }

      await this.CreateReview(
        paramsReview[key].name,
        paramsReview[key].city,
        paramsReview[key].massage,
        file
      );
    }
    console.log('Загрузка успешно произведина...');
  }

  static async upImgValue(key, value) {
    this.proverka();

    let formData = new FormData();
    formData.set('key', key);
    formData.append('file', value);

    let response = {
      status: true,
      link: 'https://4.bp.blogspot.com/-v_79H_xAr-I/WUJcMt0de9I/AAAAAAAA4bo/M-M-QtIgO_oKBJX02Z34ND7-k9Gc6AfQACLcBGAs/s1600/archlabs%2B4.1%2Bmenu.png',
    };

    await axios
      .post(this.url_api + 'take_file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*',
          Authorization: 'Bearer ' + localStorage.getItem('user_key'),
        },
        method: 'post',

        // data:{
        //     key:key,
        //     file:value
        // }
      })
      .then((res) => {
        response = {
          status: true,
          link: res.data.Response.file,
        };
      })
      .catch((error) => {
        this.Error401(error);
        response = {
          status: false,
          link: '',
        };
      });

    return response;
  }

  static async DellReview(id) {
    this.proverka();

    let response = {
      check: true,
    };

    await axios
      .delete(this.url_api + 'comment/' + id, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user_key'),
        },
      })
      .then((res) => {
        response = {
          check: true,
        };
      })
      .catch((error) => {
        this.Error401(error);
        response = {
          check: false,
        };
      });

    return response;
  }

  static async CreateReview(name, city, message, image) {
    this.proverka();

    let response = {
      check: true,
    };

    let formData = new FormData();
    formData.set('name', name);
    formData.set('city', city);
    formData.set('massage', message);

    if (image) formData.set('img', image);
    else formData.set('img', await DefaultAvatarHelper());

    await axios
      .post(this.url_api + 'comment', formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user_key'),
        },
      })
      .then((res) => {
        response = {
          check: true,
          res: res,
        };
      })
      .catch((error) => {
        this.Error401(error);
        response = {
          check: false,
          res: error,
        };
      });

    return response;
  }

  static async UpdateReview(id, name, city, message, image = null) {
    let response = {
      check: true,
    };

    let formData = new FormData();
    formData.set('name', name);
    formData.set('city', city);
    formData.set('massage', message);
    formData.set('id', id);

    if (image instanceof File) formData.set('img', image);

    await axios
      .put(this.url_api + 'comment', formData, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user_key'),
        },
      })
      .then((res) => {
        response = {
          check: true,
          res: res,
        };
      })
      .catch((error) => {
        this.Error401(error);
        response = {
          check: false,
          res: error,
        };
      });

    return response;
  }

  static async GetAllReview() {
    let response = {
      check: true,
    };

    await axios
      .get(this.url_api + 'comment')
      .then((res) => {
        response = {
          check: true,
          ten_comments: [...res?.data?.Response?.comment],
        };
      })
      .catch(() => {
        response = {
          check: false,
        };
      });

    return response;
  }

  static async Auth(login, password) {
    let response = {
      status: false,
      key: '',
    };

    await axios
      .post(
        this.url_api + 'token',
        {
          username: login,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((res) => {
        response = {
          status: true,
          key: res.data?.access_token,
        };
      })
      .catch((error) => {
        response = {
          status: false,
          key: null,
        };
      });

    return response;
  }

  static async CheckAuth() {
    const localKey = localStorage.getItem('user_key');
    if (!localKey) return false;

    let result = false;

    await axios
      .get(this.url_api + 'access_user', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user_key'),
        },
      })
      .then(() => {
        result = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          result = false;
        }
      });

    return result;
  }

  static proverka() {
    if (!localStorage.getItem('user_key')) window.location.reload();
  }

  static Error401(error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem('user_key');
      window.location.reload();
    }
  }
}

export default AdminService;
