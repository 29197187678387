import React, {useContext, useEffect, useRef} from 'react';
import classes from './SixPage.module.css'

import {GlobalContext} from "../../../contexts/GlobalContext";

const SixPage = () => {
    const {content} = useContext(GlobalContext);
    const video = useRef();

    useEffect(()=>{
        video?.current?.load();
    },[content?.six_video_1]);

    return (
        <div className={classes.SixPage}>
            <div className="headerClone"></div>

            <div className={classes.content}>

                <div className={classes.title}>
                    {content?.six_1}
                </div>

                <div className={classes.text}>
                    {content?.six_2}
                </div>

                <div className={classes.video}>
                    <video ref={video} controls={true}>
                        <source src={content?.six_video_1}/>
                    </video>
                </div>

                <div className={classes.text2}>
                    {content?.six_3}
                </div>

                <div className={classes.text2D}>
                    {content?.six_4}
                </div>

            </div>

            <div className={classes.fonTop}/>
            <div className={classes.fonBottom}/>
        </div>
    );
};

export default SixPage;