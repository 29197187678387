import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './Phone.module.css';
import phoneImg from './../../images/WhyUsPage/phone.png';
import phoneImgDesktop from './../../images/WhyUsPage/phoneImgDesktop.png';
import PhoneService from '../../Services/PhoneService';
import { GlobalContext } from '../../contexts/GlobalContext';
import { Modal } from 'antd';
import FormPage from '../../Components/Pages/FormPage/FormPage';
import { useForm } from 'antd/es/form/Form';

const Phone = ({ toFormPhone, phone, rates, isForm, setIsForm }) => {
  const [phoneActive, setPhoneActive] = useState(-1);
  const [phoneSave, setPhoneSave] = useState(null);
  const [form] = useForm();
  const [intervalId, setIntervalId] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    toFormPhone(phoneSave);
    if (form) {
      form.setFieldValue('phone_take', phoneSave?.phone);
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const MOver = () => {
    stopInterval();
  };

  useEffect(() => {
    let PhoneNumbers = document.getElementById('PhoneNumbers');
    PhoneNumbers.scrollTop = 50;
  }, []);

  const startInterval = () => {
    let PhoneNumbers = document.getElementById('PhoneNumbers');

    setIntervalId(
      setInterval(() => {
        if (PhoneNumbers.scrollTop < PhoneNumbers.scrollHeight) {
          PhoneNumbers.scrollTop = PhoneNumbers.scrollTop + 1;
        }
      }, 100)
    );
  };

  const stopInterval = () => {
    const interval_id = window.setInterval(function () {},
    Number.MAX_SAFE_INTEGER);
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  };

  // const stopTimout = () => {
  //   let id = window.setTimeout(function () {}, 0);

  //   while (id--) {
  //     window.clearTimeout(id);
  //   }
  // };

  useEffect(() => {
    startInterval();
  }, []);

  const endElement = useRef();
  const observer = useRef();
  const [observerUpdate, setObserverUpdate] = useState(false);

  const getPhones = async () => {
    setPhones([...phones, ...(await PhoneService?.getPhonesPhone(1))]);
  };

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    let callback = async (entries, observer) => {
      if (entries[0].isIntersecting) {
        await getPhones();
        setObserverUpdate(!observerUpdate);
      }
    };

    observer.current = new IntersectionObserver(callback);
    observer.current.observe(endElement.current);
  }, [observerUpdate]);

  const [phones, setPhones] = useState([]);

  const setPhone = (phone, index) => {
    setPhoneActive(index);
    setPhoneSave(phone);
  };

  const { content } = useContext(GlobalContext);

  return (
    <>
      <div className={classes.Phone}>
        <div className={classes.content}>
          <div className={classes.PhoneImg}>
            <img src={phoneImg} className={classes.imgM} alt="" />
            <img src={phoneImgDesktop} className={classes.imgD} alt="" />
          </div>

          <div
            className={classes.PhoneContainer}
            style={phones ? { marginLeft: '0px' } : { marginLeft: '10px' }}
          >
            <div
              onMouseEnter={MOver}
              onTouchMove={MOver}
              className={classes.PhoneContainerNumbers}
            >
              <div id="PhoneNumbers" className={classes.PhoneNumbers}>
                <div className={classes.whiteFogTop}></div>
                <div className={classes.whiteFogBottom}></div>

                <div className={classes.startPhones}></div>

                {phones.map((phone, index) => {
                  return (
                    <div
                      className={
                        index !== phoneActive
                          ? classes.textPhone
                          : classes.textPhoneActive
                      }
                      onClick={() => setPhone(phone, index)}
                      key={index}
                    >
                      +7{phone.phone}
                    </div>
                  );
                })}

                <div className={classes.endPhones} ref={endElement}>
                  {content?.phone_1}
                </div>
              </div>
            </div>
            <div className={classes.PhoneButton}>
              <input
                onClick={showModal}
                type="button"
                value={content?.phone_2}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Оставить заявку"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        className="bl-request-modal"
      >
        <FormPage
          phone={phone}
          rates={rates}
          isForm={isForm}
          setIsForm={setIsForm}
        />
        <div className="subtitle-modal-form">
          {
            'Нажимая на кнопку «Отправить», \nвы подтверждаете свое согласие \nна обработку'
          }{' '}
          <span className="bold">персональных данных.</span>
        </div>
        {/* <Form
          form={form}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ phone_take: phoneSave?.phone }}
        >
          <Form.Item name="phone_take" hidden={false}>
            <Input />
          </Form.Item>
          <Form.Item
            name="cli_name"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input placeholder="Имя" value={name} type="text" />
          </Form.Item>

          <Form.Item
            name="cli_phone"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <InputMask
              value={userPhone}
              mask="+7-999-999-99-99"
              type="phone"
              placeholder="+7 (_____) ____-___-___"
              style={{ marginTop: '10px' }}
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form> */}
      </Modal>
    </>
  );
};

export default Phone;
